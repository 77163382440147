@import "node_modules/bootstrap/scss/variables";
@import "../variables/layout/custom-tooltip";

/* CUSTOM TOOLTIP */

.has-tip {
	cursor: help;

	i {
		font-size: 17px;
	}
}

.tooltip {

	--bs-tooltip-bg: $custom-tooltip-background-color;

	&.show {
		opacity: 1;
	}

	.arrow:before {
		border-top-color: $custom-tooltip-background-color;
		border-bottom-color: $custom-tooltip-background-color;
	}

	.tooltip-inner {
		max-width: 200px;
		padding: 3px 8px;
		font-size: 10px;
		color: $custom-tooltip-color;
		background: $custom-tooltip-background-color;
		border-radius: 4px;
		box-shadow: 0px 0px 3px $custom-tooltip-box-shadow-color;
	}
}

.popover {
	&.show {
		opacity: 1;
	}

	.arrow:before {
		border-top-color: rgba(0,0,0,.25);
		border-bottom-color: rgba(0,0,0,.25);
	}

	.popover-body {
		font-size: 10px;
		color: $custom-tooltip-color;
		background: $custom-tooltip-background-color;
		border-radius: 4px;
	}

	&.customPopover.ticketInformationBanner .arrow:before {
		border-top-color: transparent;
		border-bottom-color: transparent;
	}
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
	top: calc((0.5rem + 0px) * -1);
}

// & is body
&.modal-open {
	.customPopover.show {
		z-index: ($zindex-modal + 1) !important;
	}
}