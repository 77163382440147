@import "../../general/import-general-only";

@mixin panelBlockTitle() {
	> .title {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 18px;
		color: $text-blue;
		vertical-align: middle;

		@include sf_pro_textbold;
	}
}

@mixin formBg($isRegisterFrom: false) {
	@if ($isRegisterFrom) {
		padding: 25px 10px 10px 10px;
	}

	@else {
		padding: 25px;
	}
	margin-bottom: 15px;
	background-color: $gray-light;
}

@mixin label() {
	label {
		padding: 0 0 0 16px;
		margin: 0;
		font-size: 14px;
		line-height: 44px;
		color: $text-blue;
		vertical-align: middle;

		@include sf_pro_textbold;
	}
}

@mixin inputField($withSelect: true) {
	@if ($withSelect) {
		input, select, textarea,
		input:focus, select:focus, textarea:focus,
		input:focus-visible, select:focus-visible, textarea:focus-visible {
			display: inline-block;
			flex-grow: 1;
			flex-basis: 0;
			height: 44px;
			margin: 0;
			background-color: $white;
			border: 2px solid $gray-placeholder;
			border-radius: 10px;
			outline-color: $gray-placeholder;
		}
	}

	@else {
		input, input:focus, input:focus-visible {
			height: 44px;
			padding-left: 10px;
			margin: 0;
			background-color: $white;
			border: 2px solid $gray-placeholder;
			border-radius: 10px;
			outline-color: $gray-placeholder;
		}
	}

	input {
		&:not([value=""]):not(:focus):invalid,
		&.invalid {
			background-color: $invalid-background;
		}

		&.emailInput:not([value=""]):not(:focus):invalid {
			background-color: $invalid-background;
			border: 2px solid $invalid-border-color;
		}
	}
}

@mixin userCustomCheckMark() {
	label {
		position: relative;
		padding: 0;
		margin: 0;
		font-size: 13px;
		line-height: 17px;
		vertical-align: middle;
		cursor: pointer;

		@include sf_pro_textregular;

		input {
			position: absolute;
			width: 0;
			height: 0;
			cursor: pointer;
			opacity: 0;

			&:checked ~ .checkmark {
				background-color: $primary-blue;
				border-color: $primary-blue;

				&:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			width: 16px;
			height: 16px;
			background-color: transparent;
			border-color: $primary-blue;
			border-style: solid;
			border-width: 2px;
			border-radius: 2px;
			border-image: initial;

			&:after {
				position: absolute;
				top: 0;
				left: 4px;
				display: none;
				width: 5px;
				height: 10px;
				content: "";
				border-color: $white;
				border-style: solid;
				border-width: 0 2px 2px 0;
				border-image: initial;
				transform: rotate(45deg);
			}
		}

		.name {
			display: inline-block;
			margin: 0 0 0 20px;
		}
	}
}

@mixin toggleBtn() {
	.inputGrp {
		>input:not([value=""]):not(:focus):invalid {
			background-color: $invalid-background;
			border: 2px solid $invalid-border-color;
		}

		.passwordInputGroup {
			display: flex;
			align-items: center;
			line-height: inherit;
			background-color: $white;
			border: 2px solid $gray-placeholder;
			border-radius: 10px;
			outline-color: $gray-placeholder;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
			transition: box-shadow 0.45s ease 0s, border-color 0.45s ease-in-out 0s;

			input {
				border: none;
				outline: none;
				box-shadow: none;
			}

			&.invalid {
				background-color: $invalid-background;
				border: 2px solid $invalid-border-color;
			}
		}

		.hasToggleBtnSibling:not(:placeholder-shown) + .toggleBtn
		{
			display: block;
			background-color: transparent;
		}

		.hasToggleBtnSibling:not(:placeholder-shown) + .toggleBtn .hideText
		{
			display: none;
		}

		.toggleBtn {
			display: none;
			height: 44px;
			padding: 0 20px 0 0;
			font-size: 14px;
			line-height: 24px;
			color: $tertiary-blue;
			vertical-align: middle;
			border: none;
			box-shadow: unset;

			@include sf_pro_textregular;
		}
	}
}

@mixin buttonRow() {
	.buttonRow {
		.submitButton, .cancelButton {
			height: 44px;
			font-size: 14px;
			line-height: 21px;
			text-align: center;
			vertical-align: middle;
			border: unset;
			border-radius: 10px;

			@include sf_pro_textsemibold;

			@include media-breakpoint-down(md) {
				width: 100%;
				margin: 0 0 10px 0 !important;
			}

			&:focus {
				box-shadow: unset;
			}
		}

		.submitButton {
			margin: 0 5px 0 0;
			color: $white;
			background-color: $tertiary-blue;
		}

		.cancelButton {
			color: $text-blue;
			background-color: $gray-placeholder;
		}
	}
}

@mixin passwordProgressContainer($zeroMargin: false) {
	.passwordProgressContainer {
		border: 1px solid $gray-placeholder;
		border-radius: 3px;

		@if ($zeroMargin) {
			margin: 0;
		}

		.title {
			font-size: 13px;
			line-height: 35px;
			color: $text-blue;
			vertical-align: middle;

			@include sf_pro_textsemibold;
		}

		@include progressBar();
	}
}

@mixin progressBar() {
	.progress {
		height: 6px;
		margin: 14px 0;
		background-color: transparent;
		border-radius: 4px;

		.progress-bar {
			height: 6px;
			background-color: $secondary-green;
			border-radius: 4px;
		}

		&.tooWeak .progress-bar:nth-of-type(n+2) {
			background-color: $gray-placeholder;
		}

		&.weak .progress-bar:nth-of-type(n+3) {
			background-color: $gray-placeholder;
		}

		&.medium .progress-bar:nth-of-type(n+4) {
			background-color: $gray-placeholder;
		}
	}
}

@mixin formatSuccessMessage() {
	font-size: 14px;
	color: $secondary-green-hover;
	background-color: $secondary-green-light;
	border: 1px solid $secondary-green-light;
	border-radius: 3px;

	@include sf_pro_textsemibold;

	button.close {
		position: absolute;
		top: 10px;
		right: 0;
		font-size: 1.4em;
		color: $black !important;
		text-shadow: none;
		background: none;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		opacity: 1;

		&:focus, &:focus-visible {
			outline: unset;
			box-shadow: unset;
		}
	}
}

@mixin formatYourTripsArrangementLogo() {
	.logos {
		text-align: center;

		img {
			width: 74px;
			height: auto;
		}
	}
}

@mixin formatBadges() {
	.badges {
		margin: 25px 0 0 0;
		text-align: center;

		.badge {
			padding: 5px 8px;
			font-size: 13px;
			color: $text-blue;
			background-color: $primary-blue-light;
			border-radius: 3px;
			opacity: 1;

			@include sf_pro_textregular;
		}
	}
}

@mixin separatorForBookedItems() {
	margin: 10px 0;
	border-bottom: 2px dashed $gray-placeholder;
}

@mixin formatBadgesForBookedItems() {
	width: 30px;
	height: 22px;
	margin: 0 10px 0 0;
	line-height: 18px;
	color: $primary-blue-light;
	vertical-align: middle;
	background-color: $text-blue;

	@include sf_pro_textsemibold;
}

@mixin formatYourTripsMatchName() {
	.matchName {
		margin: 0 0 10px 0;
		font-size: 20px;
		line-height: 26px;
		color: $text-blue;

		@include sf_pro_textbold;
	}
}

@mixin modalForUserPages() {
	.modal.modalForUserPages .modal-dialog {
		@include media-breakpoint-up(md) {
			max-width: 660px;
		}

		.modal-content {
			background-color: $primary-blue;

			@include media-breakpoint-up(md) {
				width: 660px;
			}

			.modal-header {
				padding: 0;
				margin: 25px 25px 20px 25px;
				border: unset;

				.modal-title {
					font-size: 20px;
					line-height: 24px;
					color: $white;

					@include sf_pro_textsemibold;
				}

				.close {
					height: 24px;
					line-height: 24px;
					color: $white;
					text-shadow: unset;
					box-shadow: unset;
					opacity: 1;
				}
			}

			.modal-body {
				padding: 20px;
				margin: 0 10px 10px 10px;
				background-color: $white;
				border-radius: 3px;

				.buttonRow button {
					height: auto;
					padding: 0 24px;
					margin: 0 5px 0 0;
					font-size: 14px;
					line-height: 44px;
					text-align: center;
					border: unset;
					border-radius: 10px;
					box-shadow: unset;

					@include sf_pro_textsemibold;

					@include media-breakpoint-down(lg) {
						display: block;
						width: 100%;

						&.submitButton {
							margin: 0 0 10px 0;
						}
					}

					&.submitButton {
						color: $white;
						background-color: $tertiary-blue;
					}

					&.cancelButton {
						float: unset;
						color: $text-blue;
						background-color: $gray-placeholder;
						opacity: 1;
					}
				}
			}
		}
	}
}

@mixin formatbackToPrevious() {
	height: auto;
	color: $tertiary-blue;
	text-decoration: none;
	cursor: pointer;

	@include sf_pro_textlight();

	&:hover {
		text-decoration: none;
	}

	i {
		line-height: 18px;
		vertical-align: middle;
	}

	span {
		font-size: 14px;
		line-height: 18px;
		text-decoration: underline;
		vertical-align: middle;

		@include sf_pro_textregular;
	}
}

// YOUR TRIP => TRIP PANEL
@mixin yourTrip_trip_blockDefaults() {
	padding: 10px;
	font-size: 14px;
	line-height: 18px;
	color: $text-blue;
	background-color: $gray-light;
	border-radius: 3px;

	@include sf_pro_textregular;

	&:not(:last-child) {
		margin: 0 0 10px 0;
	}

	> {
		@include titleInsideTheBlock();
	}

	@include customListBullet();
}

@mixin titleInsideTheBlock() {
	.title {
		padding: 0 10px;
		margin: 5px 0 10px 0;
		font-size: 14px;
		line-height: 18px;
		color: $text-blue;
		vertical-align: middle;

		@include sf_pro_textbold;
	}
}

@mixin yourTrip_trip_detailsDefaults() {
	padding: 10px;
	margin: 0;
	background-color: $white;
	border-radius: 3px;

	> [class^="col"] {
		padding: 0;
	}
}

@mixin yourTrip_trip_linkDefauls() {
	.link {
		padding: 0;
		text-align: right;

		a {
			display: inline-block;
			padding: 0 20px;
			font-size: 14px;
			line-height: 44px;
			color: $white;
			text-decoration: unset;
			vertical-align: middle;
			background-color: $tertiary-blue;
			border-radius: 10px;

			@include sf_pro_textsemibold;
		}

		@include media-breakpoint-down(lg) {
			text-align: center;

			a {
				display: block;
			}
		}
	}
}

@mixin yourTrip_trip_comments() {
	.comments {
		padding-bottom: 10px;
		font-size: 14px;
		color: $text-blue;

		@include sf_pro_textregular;
		@include customListBullet();
	}
}

@mixin customListBullet() {
	ul {
		padding: 0 0 0 15px;
		margin: 0;

		li {
			line-height: 18px;
			vertical-align: middle;

			&::marker {
				font-size: 7px;
				color: $text-blue;
			}
		}
	}
}

@mixin legalStatements() {
	.legal-statements-holder {
		font-size: 12px;
		color: $gray-secondary;

		@include media-breakpoint-up(sm) {
			text-align: center;
		}

		a {
			color: $tertiary-blue;
			text-decoration: underline;

			&:hover {
				color: $tertiary-blue-hover;
				text-decoration: none;
			}
		}

		.horizontal-line {
			width: 88%;
			margin: 20px auto 5px;
			border-top: 1px solid $gray-secondary;
		}
	}
}