@import "node_modules/bootstrap/scss/mixins/banner";

@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "node_modules/bootstrap/scss/functions";
@import "bs5-settings";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Utilities
@import "node_modules/bootstrap/scss/bootstrap-utilities";

// Layout & components
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/accordion";

.media {
	display: flex;
	align-items: flex-start;
}

.media-body {
	flex: 1;
}

A, .btn-link {
	text-decoration: none;
}

.alert {
	.close {
		position: absolute;
		top: 10px;
		right: 0;
		font-size: 1.4em;
		color: $black !important;
		text-shadow: none;
		background: none;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		opacity: 1;
	}
}

.visually-hidden {
	display: none;
}


.clearfix::after {
	display: block;
	clear: both;
	content: "";
}
