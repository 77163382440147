@import "../../general/import-general-only";

$easyBookingManagementPromo-color: $gray-text-default !default;
$easyBookingManagementPromo-background: $tertiary-blue-light !default;
$easyBookingManagementPromo-button-color: $tertiary-blue !default;
$easyBookingManagementPromo-button-hover-color: $primary-blue !default;

.easy-booking-management-promo {
	padding: 10px;
	font-size: 14px;
	color: $easyBookingManagementPromo-color;
	background-color: $easyBookingManagementPromo-background;

	.btn {
		--bs-btn-font-size: 14px;
		height: auto;
		padding-right: 0;
		padding-left: 0;
		color: $easyBookingManagementPromo-button-color;
		box-shadow: none;

		&:hover {
			color: $easyBookingManagementPromo-button-hover-color
		}
	}
}