@import "import";
// Debugging the breakpoints
// @include debugBreakpoints;

@import "general/bs5-settings";
@import "variables/base";

body {
	@import "layout/general-rules";
	@import "layout/footer";
	@import "layout/cookiebar";
	@import "layout/newsletter";
	@import "layout/user/login";
	@import "layout/custom-tooltip";
	@import "layout/language-selector";
	// if showPrizeGame2021Modal
	//@import "layout/common/modals/prize-game-modal";

	.userForm .invalid-feedback:not(.hidden-element) {
		display: block;
	}

	&.user-is-logged-in {
		.hidden-for-logged-in-user {
			display: none !important;
		}
	}

	//hide content that should only be visible for logged in users
	&:not(.user-is-logged-in) {
		.visible-for-logged-in-user {
			display: none !important;
		}
	}
}
