$icomoon-font-path: $eventTripsIconPath;

@import "eventtripsicon/variables";
@import "eventtripsicon/style";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?423ynr') format('woff2'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?423ynr') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?423ynr##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}