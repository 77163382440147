@import "../variables/layout/newsletter";

#newslettermodal_invalid_email_format,
#newslettermodal_already_subscribed,
#newslettermodal_unknow_error,
#newslettermodal_success_signup {
	z-index: 1060;

	.modal-content {
		.modal-header {
			border-bottom: none;

			h5.modal-title{
				font-size: 22px;
				color: $newsletterModal-title-color;
			}
		}

		.modal-body {
			.alert {
				margin-bottom: 0;

				span.fas {
					margin-right: 10px;
				}
			}
		}

		.modal-footer {
			border-top: none;

			button.btn {
				padding: 0 10px;
				margin: 0;
				line-height: 30px;
				color: $newsletterModal-close-btn-color;
				box-shadow: unset;
			}
		}
	}
}