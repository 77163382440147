@import "../../variables/layout/common/mobile-newsletter";

@mixin mobile_newsletter() {
	text-align: left;

	.row {
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
			padding: 30px 15px 10px 15px;
			margin: 0;
			font-size: 14px;
			color: $mobileNewsLetter-color;
			background: $mobileNewsLetter-background-type2;

			@include sf_pro_textlight;

			H2 {
				font-size: 18px;

				@include sf_pro_textsemibold;
			}

			[class*='col-'] {
				padding: 0;
				margin: 0;

				FORM {
					INPUT {
						display: inline-block;
						width: 100%;
						height: 55px;
						padding: 5px 15px;
						margin: 15px 0 0 0;
						font-size: 16px;
						color: $mobileNewsLetter-form-input-color;
						background: $mobileNewsLetter-form-input-backgrund;
						border: 2px solid $gray-placeholder;
						border-radius: 10px;

						&:first-letter {
							text-transform: uppercase;
						}
					}

					BUTTON {
						display: inline-block;
						width: 100%;
						height: 55px;
						padding: 0;
						margin: 5px 0 10px 0;
						font-size: 14px;
						color: $mobileNewsLetter-form-redesign-button-color;
						cursor: pointer;
						background: $mobileNewsLetter-form-redesign-button-background;
						border: 2px solid $mobileNewsLetter-form-redesign-button-border-color;
						border-radius: 10px;
						box-shadow: unset;

						&:first-letter {
							text-transform: uppercase;
						}

						&:hover {
							color: $mobileNewsLetter-form-button-hover-color;
							background: $mobileNewsLetter-form-button-hover-background;
							transition: background 0.3s ease 0s;
						}
					}
				}
			}
		}
	}
}

