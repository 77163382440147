$path: $sfProTextPath;

@font-face {
	font-family: 'sf_pro_textlight';
  src: url($path + '/sf-pro-text-light-webfont.woff2') format('woff2'),
        url($path + '/sf-pro-text-light-webfont.woff') format('woff'),
        url($path + '/sf-pro-text-light-webfont.svg#sf_pro_textregular') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'sf_pro_textregular';
  src: url($path + '/sf-pro-text-regular-webfont.woff2') format('woff2'),
        url($path + '/sf-pro-text-regular-webfont.woff') format('woff'),
        url($path + '/sf-pro-text-regular-webfont.svg#sf_pro_textregular') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'sf_pro_textbold';
  src: url($path + '/sf-pro-text-bold-webfont.woff2') format('woff2'),
        url($path + '/sf-pro-text-bold-webfont.woff') format('woff'),
        url($path + '/sf-pro-text-bold-webfont.svg#sf_pro_textbold') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'sf_pro_textsemibold';
  src: url($path + '/sf-pro-text-semibold-webfont.woff2') format('woff2'),
        url($path + '/sf-pro-text-semibold-webfont.woff') format('woff'),
        url($path + '/sf-pro-text-semibold-webfont.svg#sf_pro_textsemibold') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'sf_pro_textmedium';
  src: url($path + '/sf-pro-text-medium-webfont.woff2') format('woff2'),
        url($path + '/sf-pro-text-medium-webfont.woff') format('woff'),
        url($path + '/sf-pro-text-medium-webfont.svg#sf_pro_textmedium') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'sf_pro_textultralight';
  src: url($path + '/sf-pro-text-ultralight-webfont.woff2') format('woff2'),
        url($path + '/sf-pro-text-ultralight-webfont.woff') format('woff'),
        url($path + '/sf-pro-text-ultralight-webfont.svg#sf_pro_textultralight') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}
