footer {
	.footerContainer {
		.questions {
			background-color: $fbr-headings;

			p {
				img.garantee {
					width: 150px;
					margin: 10px 0;
				}
			}
		}

		.whychoose .row .tableContainer .tableRow .tableCell .box A.link_under {
			color: $fbr-interactions;
		}

		.info A {
			color: $fbr-interactions;

			&:hover, &:focus {
				color: $fbr-interactions;
			}
		}

	}
}

