@import "../variables/layout/cookiebar";

#cookiebar {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	margin: 0;
	background: $cookiebar-background;

	.left {
		text-align: left;
	}

	.right {
		text-align: right;
	}

	.left, .right {
		margin: 5px 0;
		font-size: 12px;
		color: $cookiebar-color;

		a {
			color: $cookiebar-a-color;
			cursor: pointer;

			span.underline {
				text-decoration: underline;
			}
		}

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}
}