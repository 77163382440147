@import "../variables/layout/general-rules";

/* CUSTOM TAG FOR MISSING TRANS KEYS*/
missingkey { /* stylelint-disable-line selector-type-no-unknown */
	padding: 0 10px !important;
	font-size: 12px !important;
	color: $yellow-1 !important;
	background-color: $black !important;
	border: 3px dashed red !important;
}

.centeredCtaButton {
	@include sf_pro_textbold;
	display: block;
	width: 250px;
	height: auto;
	padding: 0 15px;
	margin: 40px auto 10px;
	font-size: 16px;
	line-height: 41px;
	color: $ctaBtn-color !important;
	text-align: center;
	background: $white;
	border: 3px solid $ctaBtn-border-color;
	box-shadow: none !important;
	transition: background 0.3s ease 0s;

	&:hover {
		color: $white !important;
		background: $ctaBtn-hover-background-color;
		box-shadow: none !important;
		transition: background 0.3s ease 0s;
	}
}

.modal {
	-webkit-overflow-scrolling: touch;
}

.modal-content {
	.modal-header {
		.modal-title {
			line-height: 30px;
		}

		.close {
			padding: unset;
			margin: unset;
			font-size: 1.5em;
			color: $black;
			text-shadow: none;
			background: none;
			border: 0;
			box-shadow: none;
			opacity: 0.5;
		}
	}

	.modal-footer {
		.btn {
			padding-top: 0;
			padding-bottom: 0;
			margin: 0;
			line-height: unset;
			box-shadow: none;
		}
	}
}

.iframe-frameborder-0 {
	border: 0;
}