@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?cg35al');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?cg35al#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?cg35al') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?cg35al') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?cg35al##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="eventTripsIcon-"], [class*=" eventTripsIcon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.eventTripsIcon-single-ticket {
  &:before {
    content: $eventTripsIcon-single-ticket;     
    color: #004f84;
  }
}
.eventTripsIcon-snowflake {
  &:before {
    content: $eventTripsIcon-snowflake; 
  }
}
.eventTripsIcon-needTime {
  &:before {
    content: $eventTripsIcon-needTime; 
  }
}
.eventTripsIcon-singleInfo {
  &:before {
    content: $eventTripsIcon-singleInfo; 
  }
}
.eventTripsIcon-warningInfo {
  &:before {
    content: $eventTripsIcon-warningInfo; 
  }
}
.eventTripsIcon-bell {
  &:before {
    content: $eventTripsIcon-bell; 
  }
}
.eventTripsIcon-shield-2 {
  &:before {
    content: $eventTripsIcon-shield-2; 
  }
}
.eventTripsIcon-post-box {
  &:before {
    content: $eventTripsIcon-post-box;     
    color: #12194d;
  }
}
.eventTripsIcon-logout {
  &:before {
    content: $eventTripsIcon-logout; 
  }
}
.eventTripsIcon-money1 {
  &:before {
    content: $eventTripsIcon-money1; 
  }
}
.eventTripsIcon-arrow-icon-size3 {
  &:before {
    content: $eventTripsIcon-arrow-icon-size3; 
  }
}
.eventTripsIcon-plane-horizontally {
  &:before {
    content: $eventTripsIcon-plane-horizontally; 
  }
}
.eventTripsIcon-envelope-filled {
  &:before {
    content: $eventTripsIcon-envelope-filled; 
  }
}
.eventTripsIcon-envelope-outlined {
  &:before {
    content: $eventTripsIcon-envelope-outlined; 
  }
}
.eventTripsIcon-enveloop {
  &:before {
    content: $eventTripsIcon-enveloop; 
  }
}
.eventTripsIcon-alert-circle {
  &:before {
    content: $eventTripsIcon-alert-circle; 
  }
}
.eventTripsIcon-clock-alert {
  &:before {
    content: $eventTripsIcon-clock-alert; 
  }
}
.eventTripsIcon-luggage {
  &:before {
    content: $eventTripsIcon-luggage; 
  }
}
.eventTripsIcon-ticket {
  &:before {
    content: $eventTripsIcon-ticket; 
  }
}
.eventTripsIcon-sleep {
  &:before {
    content: $eventTripsIcon-sleep; 
  }
}
.eventTripsIcon-user {
  &:before {
    content: $eventTripsIcon-user; 
  }
}
.eventTripsIcon-person-single {
  &:before {
    content: $eventTripsIcon-person-single; 
  }
}
.eventTripsIcon-envelope2 {
  &:before {
    content: $eventTripsIcon-envelope2; 
  }
}
.eventTripsIcon-phone {
  &:before {
    content: $eventTripsIcon-phone; 
  }
}
.eventTripsIcon-phone-outline {
  &:before {
    content: $eventTripsIcon-phone-outline; 
  }
}
.eventTripsIcon-shield {
  &:before {
    content: $eventTripsIcon-shield; 
  }
}
.eventTripsIcon-material-shield {
  &:before {
    content: $eventTripsIcon-material-shield; 
  }
}
.eventTripsIcon-fab-facebook-f {
  &:before {
    content: $eventTripsIcon-fab-facebook-f; 
  }
}
.eventTripsIcon-fab-instagram {
  &:before {
    content: $eventTripsIcon-fab-instagram; 
  }
}
.eventTripsIcon-far-clock {
  &:before {
    content: $eventTripsIcon-far-clock; 
  }
}
.eventTripsIcon-far-play-circle {
  &:before {
    content: $eventTripsIcon-far-play-circle; 
  }
}
.eventTripsIcon-far-heart {
  &:before {
    content: $eventTripsIcon-far-heart; 
  }
}
.eventTripsIcon-fas-heart {
  &:before {
    content: $eventTripsIcon-fas-heart; 
  }
}
.eventTripsIcon-fas-lightbulb {
  &:before {
    content: $eventTripsIcon-fas-lightbulb; 
  }
}
.eventTripsIcon-fas-futbol {
  &:before {
    content: $eventTripsIcon-fas-futbol; 
  }
}
.eventTripsIcon-fas-exclamation-circle {
  &:before {
    content: $eventTripsIcon-fas-exclamation-circle; 
  }
}
.eventTripsIcon-fas-trophy {
  &:before {
    content: $eventTripsIcon-fas-trophy; 
  }
}
.eventTripsIcon-fas-globe-americas {
  &:before {
    content: $eventTripsIcon-fas-globe-americas; 
  }
}
.eventTripsIcon-fas-coins {
  &:before {
    content: $eventTripsIcon-fas-coins; 
  }
}
.eventTripsIcon-fas-map-marker {
  &:before {
    content: $eventTripsIcon-fas-map-marker; 
  }
}
.eventTripsIcon-fas-search {
  &:before {
    content: $eventTripsIcon-fas-search; 
  }
}
.eventTripsIcon-fas-sort {
  &:before {
    content: $eventTripsIcon-fas-sort; 
  }
}
.eventTripsIcon-fas-filter {
  &:before {
    content: $eventTripsIcon-fas-filter; 
  }
}
.eventTripsIcon-fas-calendar {
  &:before {
    content: $eventTripsIcon-fas-calendar; 
  }
}
.eventTripsIcon-fas-chevron-down {
  &:before {
    content: $eventTripsIcon-fas-chevron-down; 
  }
}
.eventTripsIcon-fas-chevron-left {
  &:before {
    content: $eventTripsIcon-fas-chevron-left; 
  }
}
.eventTripsIcon-fas-chevron-right {
  &:before {
    content: $eventTripsIcon-fas-chevron-right; 
  }
}
.eventTripsIcon-fas-angle-left {
  &:before {
    content: $eventTripsIcon-fas-angle-left; 
  }
}
.eventTripsIcon-fas-angle-right {
  &:before {
    content: $eventTripsIcon-fas-angle-right; 
  }
}
.eventTripsIcon-fas-arrow-left {
  &:before {
    content: $eventTripsIcon-fas-arrow-left; 
  }
}
.eventTripsIcon-fas-camera {
  &:before {
    content: $eventTripsIcon-fas-camera; 
  }
}
.eventTripsIcon-fas-check {
  &:before {
    content: $eventTripsIcon-fas-check; 
  }
}
.eventTripsIcon-fas-check-circle {
  &:before {
    content: $eventTripsIcon-fas-check-circle; 
  }
}
.eventTripsIcon-fas-info {
  &:before {
    content: $eventTripsIcon-fas-info; 
  }
}
.eventTripsIcon-fas-info-circle {
  &:before {
    content: $eventTripsIcon-fas-info-circle; 
  }
}
.eventTripsIcon-fas-plus {
  &:before {
    content: $eventTripsIcon-fas-plus; 
  }
}
.eventTripsIcon-fas-minus {
  &:before {
    content: $eventTripsIcon-fas-minus; 
  }
}
.eventTripsIcon-fas-fast-backward {
  &:before {
    content: $eventTripsIcon-fas-fast-backward; 
  }
}
.eventTripsIcon-fas-step-backward {
  &:before {
    content: $eventTripsIcon-fas-step-backward; 
  }
}
.eventTripsIcon-fas-step-forward {
  &:before {
    content: $eventTripsIcon-fas-step-forward; 
  }
}
.eventTripsIcon-fas-fast-forward {
  &:before {
    content: $eventTripsIcon-fas-fast-forward; 
  }
}
.eventTripsIcon-fas-times {
  &:before {
    content: $eventTripsIcon-fas-times; 
  }
}
.eventTripsIcon-fas-times-circle {
  &:before {
    content: $eventTripsIcon-fas-times-circle; 
  }
}
.eventTripsIcon-fas-window-minimize {
  &:before {
    content: $eventTripsIcon-fas-window-minimize; 
  }
}
.eventTripsIcon-vertical-align-top {
  &:before {
    content: $eventTripsIcon-vertical-align-top; 
  }
}
.eventTripsIcon-arrow-3 {
  &:before {
    content: $eventTripsIcon-arrow-3; 
  }
}
.eventTripsIcon-photos-open {
  &:before {
    content: $eventTripsIcon-photos-open; 
  }
}
.eventTripsIcon-close-icon-2 {
  &:before {
    content: $eventTripsIcon-close-icon-2; 
  }
}
.eventTripsIcon-check-icon-3 {
  &:before {
    content: $eventTripsIcon-check-icon-3; 
  }
}
.eventTripsIcon-check-icon-2 {
  &:before {
    content: $eventTripsIcon-check-icon-2; 
  }
}
.eventTripsIcon-users-2 {
  &:before {
    content: $eventTripsIcon-users-2; 
  }
}
.eventTripsIcon-tickets-logo {
  &:before {
    content: $eventTripsIcon-tickets-logo; 
  }
}
.eventTripsIcon-plane-2 {
  &:before {
    content: $eventTripsIcon-plane-2; 
  }
}
.eventTripsIcon-football-ball-4 {
  &:before {
    content: $eventTripsIcon-football-ball-4; 
  }
}
.eventTripsIcon-football-ball-3 {
  &:before {
    content: $eventTripsIcon-football-ball-3; 
  }
}
.eventTripsIcon-discountMotwBg {
  &:before {
    content: $eventTripsIcon-discountMotwBg; 
  }
}
.eventTripsIcon-discountLeftBg {
  &:before {
    content: $eventTripsIcon-discountLeftBg; 
  }
}
.eventTripsIcon-users {
  &:before {
    content: $eventTripsIcon-users; 
  }
}
.eventTripsIcon-ticket-check2 {
  &:before {
    content: $eventTripsIcon-ticket-check2; 
  }
}
.eventTripsIcon-arrow-2 {
  &:before {
    content: $eventTripsIcon-arrow-2; 
  }
}
.eventTripsIcon-taxi {
  &:before {
    content: $eventTripsIcon-taxi; 
  }
}
.eventTripsIcon-fan2fan {
  &:before {
    content: $eventTripsIcon-fan2fan; 
  }
}
.eventTripsIcon-roundedShadow {
  &:before {
    content: $eventTripsIcon-roundedShadow; 
  }
}
.eventTripsIcon-flame {
  &:before {
    content: $eventTripsIcon-flame; 
  }
}
.eventTripsIcon-info-3 {
  &:before {
    content: $eventTripsIcon-info-3; 
  }
}
.eventTripsIcon-info-2 {
  &:before {
    content: $eventTripsIcon-info-2; 
  }
}
.eventTripsIcon-info-1 {
  &:before {
    content: $eventTripsIcon-info-1; 
  }
}
.eventTripsIcon-mobileLoadingScreen {
  &:before {
    content: $eventTripsIcon-mobileLoadingScreen; 
  }
}
.eventTripsIcon-circle-check-icon {
  &:before {
    content: $eventTripsIcon-circle-check-icon; 
  }
}
.eventTripsIcon-minus-1 {
  &:before {
    content: $eventTripsIcon-minus-1; 
  }
}
.eventTripsIcon-plus-1 {
  &:before {
    content: $eventTripsIcon-plus-1; 
  }
}
.eventTripsIcon-close-1 {
  &:before {
    content: $eventTripsIcon-close-1; 
  }
}
.eventTripsIcon-check-icon {
  &:before {
    content: $eventTripsIcon-check-icon; 
  }
}
.eventTripsIcon-ticket-icon {
  &:before {
    content: $eventTripsIcon-ticket-icon; 
  }
}
.eventTripsIcon-tent-icon {
  &:before {
    content: $eventTripsIcon-tent-icon; 
  }
}
.eventTripsIcon-soccer-icon {
  &:before {
    content: $eventTripsIcon-soccer-icon; 
  }
}
.eventTripsIcon-opera-icon {
  &:before {
    content: $eventTripsIcon-opera-icon; 
  }
}
.eventTripsIcon-hotel-icon {
  &:before {
    content: $eventTripsIcon-hotel-icon; 
  }
}
.eventTripsIcon-football-ball-2 {
  &:before {
    content: $eventTripsIcon-football-ball-2; 
  }
}
.eventTripsIcon-plane-rightToLeft {
  &:before {
    content: $eventTripsIcon-plane-rightToLeft; 
  }
}
.eventTripsIcon-plane-leftToRight {
  &:before {
    content: $eventTripsIcon-plane-leftToRight; 
  }
}
.eventTripsIcon-football-ball {
  &:before {
    content: $eventTripsIcon-football-ball; 
  }
}
.eventTripsIcon-vibaia-car {
  &:before {
    content: $eventTripsIcon-vibaia-car; 
  }
}
.eventTripsIcon-vibaia-bullet {
  &:before {
    content: $eventTripsIcon-vibaia-bullet; 
  }
}
.eventTripsIcon-vibaia-stars-0 {
  &:before {
    content: $eventTripsIcon-vibaia-stars-0; 
  }
}
.eventTripsIcon-vibaia-stars-3 {
  &:before {
    content: $eventTripsIcon-vibaia-stars-3; 
  }
}
.eventTripsIcon-vibaia-stars-2 {
  &:before {
    content: $eventTripsIcon-vibaia-stars-2; 
  }
}
.eventTripsIcon-star-full {
  &:before {
    content: $eventTripsIcon-star-full; 
  }
}
.eventTripsIcon-star-half {
  &:before {
    content: $eventTripsIcon-star-half; 
  }
}
.eventTripsIcon-vibaia-bike-3 {
  &:before {
    content: $eventTripsIcon-vibaia-bike-3; 
  }
}
.eventTripsIcon-vibaia-boat {
  &:before {
    content: $eventTripsIcon-vibaia-boat; 
  }
}
.eventTripsIcon-vibaia-plane {
  &:before {
    content: $eventTripsIcon-vibaia-plane; 
  }
}
.eventTripsIcon-vibaia-cancel {
  &:before {
    content: $eventTripsIcon-vibaia-cancel; 
  }
}
.eventTripsIcon-vibaia-checkmark {
  &:before {
    content: $eventTripsIcon-vibaia-checkmark; 
  }
}
.eventTripsIcon-vibaia-arrowDown {
  &:before {
    content: $eventTripsIcon-vibaia-arrowDown; 
  }
}
.eventTripsIcon-vibaia-mapMarker {
  &:before {
    content: $eventTripsIcon-vibaia-mapMarker; 
  }
}
.eventTripsIcon-vibaia-distnce {
  &:before {
    content: $eventTripsIcon-vibaia-distnce; 
  }
}
.eventTripsIcon-vibaia-road {
  &:before {
    content: $eventTripsIcon-vibaia-road; 
  }
}
.eventTripsIcon-vibaia-bed {
  &:before {
    content: $eventTripsIcon-vibaia-bed; 
  }
}
.eventTripsIcon-vibaia-stars-1 {
  &:before {
    content: $eventTripsIcon-vibaia-stars-1; 
  }
}
.eventTripsIcon-vibaia-calendar {
  &:before {
    content: $eventTripsIcon-vibaia-calendar; 
  }
}
.eventTripsIcon-PBE-seat {
  &:before {
    content: $eventTripsIcon-PBE-seat; 
  }
}
.eventTripsIcon-PBE-book {
  &:before {
    content: $eventTripsIcon-PBE-book; 
  }
}
.eventTripsIcon-PBE-lunch {
  &:before {
    content: $eventTripsIcon-PBE-lunch; 
  }
}
.eventTripsIcon-PBE-drink {
  &:before {
    content: $eventTripsIcon-PBE-drink; 
  }
}
.eventTripsIcon-PBE-other {
  &:before {
    content: $eventTripsIcon-PBE-other; 
  }
}
.eventTripsIcon-new-tab {
  &:before {
    content: $eventTripsIcon-new-tab; 
  }
}
.eventTripsIcon-warning {
  &:before {
    content: $eventTripsIcon-warning; 
  }
}
.eventTripsIcon-user-football {
  &:before {
    content: $eventTripsIcon-user-football; 
  }
}
.eventTripsIcon-user-calendar {
  &:before {
    content: $eventTripsIcon-user-calendar; 
  }
}
.eventTripsIcon-user-creditcard {
  &:before {
    content: $eventTripsIcon-user-creditcard; 
  }
}
.eventTripsIcon-user-pdf {
  &:before {
    content: $eventTripsIcon-user-pdf; 
  }
}
.eventTripsIcon-user-arrival-flight {
  &:before {
    content: $eventTripsIcon-user-arrival-flight; 
  }
}
.eventTripsIcon-user-departure-flight {
  &:before {
    content: $eventTripsIcon-user-departure-flight; 
  }
}
.eventTripsIcon-user-info-icon {
  &:before {
    content: $eventTripsIcon-user-info-icon; 
  }
}
.eventTripsIcon-user-info-bed {
  &:before {
    content: $eventTripsIcon-user-info-bed; 
  }
}
.eventTripsIcon-clock {
  &:before {
    content: $eventTripsIcon-clock;     
    color: #000;
  }
}
.eventTripsIcon-faq {
  &:before {
    content: $eventTripsIcon-faq; 
  }
}
.eventTripsIcon-f1-icon {
  &:before {
    content: $eventTripsIcon-f1-icon; 
  }
}
.eventTripsIcon-nfl-icon {
  &:before {
    content: $eventTripsIcon-nfl-icon; 
  }
}
.eventTripsIcon-icehockey-icon {
  &:before {
    content: $eventTripsIcon-icehockey-icon; 
  }
}

